import React from "react";
import { BeatLoader } from "react-spinners";

export default function Loader() {
  return (
    <div className="message-container-bot">
      <div className="bot-message">
        <BeatLoader color="#ac295b" size={8} />
      </div>
    </div>
  );
}
