import React, { useEffect, useRef } from "react";
import Loader from "./Loader";
import { Carousel } from "antd";
import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

export default function Messages({ messages, streamMessage, loading }) {
  const el = useRef(null);
  useEffect(() => {
    // console.log('Here')
    el.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, [messages]);

  const formatBold = (text) => {
    // Regular expression to match anything between two pairs of asterisks (**)
    const boldPattern = /\*\*(.*?)\*\*/g;

    // Replace the pattern with JSX <strong> elements
    const parts = text.split(boldPattern);

    return parts.map((part, index) => {
      // Every second element in parts is the bold content
      if (index % 2 === 1) {
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  };

  const renderMessage = (message, index) => {
    if (typeof message === "string") {
      return (
        <div key={index} className="message-container-bot">
          <div className="bot-message">
            <ReactMarkdown
              rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
              className={"markdown-text"}
            >
              {message}
            </ReactMarkdown>
          </div>
        </div>
      );
    }

    const { type, answer, sources, images } = message;
    console.log(message);
    return type === "bot" ? (
      <div key={index} className="message-container-bot">
        <div className="bot-message">
          <div>
            <ReactMarkdown
              rehypePlugins={[[rehypeExternalLinks, { target: "_blank" }]]}
              className={"markdown-text"}
            >
              {answer}
            </ReactMarkdown>
          </div>
          {sources && sources.length > 0 && (
            <div className="sources-container">
              <span style={{ fontWeight: "500" }}>
                For insights, check our sources or resources.{"\n\n"}
              </span>
              {sources.map((source, sourceIndex) => {
                const [sourceName, sourceUrl] = Object.entries(source)[0];
                return (
                  <div key={sourceIndex} className="source-item">
                    <a
                      href={sourceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {sourceName}
                    </a>
                  </div>
                );
              })}
              {images && images.length > 0 && (
                <div className="images-container">
                  <Carousel autoplay arrows>
                    {images.map((image, imageIndex) => (
                      <div key={imageIndex}>
                        <a href={image} target="_blank">
                          <img
                            src={image}
                            alt={`Image ${imageIndex + 1}`}
                            className="message-image"
                          />
                        </a>
                      </div>
                    ))}
                  </Carousel>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div key={index} className="message-container-user">
        <div className="user-message">{answer}</div>
      </div>
    );
  };

  return (
    <div className="messages">
      {messages.map((message, index) => renderMessage(message, index))}
      {streamMessage && renderMessage(streamMessage, "stream-message")}
      {loading && <Loader />}
      <div id={"el"} ref={el} />
    </div>
  );
}
