import React from "react";
import im from '../assets/logo-head.png';

export default function Header() {
  return <div className="header">
    <div className="header-icon">
    <img src={im} alt="ChatBot Icon" width={30} height={40} />
    </div>
    <div className="header-text">
    &nbsp;Sentinel Net Lease
    </div>
    </div>;
}
