import chatbotIcon from '../assets/logo-head.png'
import React, { useState, useEffect } from 'react';
import ChatBot from '../components/ChatBot';
import '../styles/styles.css';


function ChatPage() {
    const [showChatBot, setShowChatBot] = useState(false);
    const [apiError, setApiError] = useState(null);
    
    const toggleChatBot = () => {
        setShowChatBot((prevState) => !prevState);
    };

    useEffect(() => {

    }, []);

    document.body.style = 'background: rgb(255 238 238);';

    return (

        <div className="chat-page">
            {!apiError && <div className={`chatbot-icon ${showChatBot ? 'active' : ''}`} onClick={toggleChatBot}>
                <div className='chatbot-icon-div'>
                <img src={chatbotIcon} className="chatbot-icon" alt="ChatBot Icon" width={40} height={50} />
                </div>
            </div>}

            {apiError ? (
                <div className="error-message">{apiError}</div>
            ) : (
                showChatBot && <ChatBot onClose={toggleChatBot} />
            )}
        </div>
    );
}

export default ChatPage;
