import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import BotMessage from "./BotMessage";
import UserMessage from "./UserMessage";
import Messages from "./Messages";
import Input from "./Input";
import "../styles/styles.css";
import Header from "./Header";

function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [streamMessage, setStreamMessage] = useState("");

  const [socket, setSocket] = useState(null);
  const inpRef = useRef(null);
  const sendRef = useRef(null);
  const [text, setText] = useState("");
  const [unique, setUnique] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newSocket = io("/"); // Replace with your server URL
    setSocket(newSocket);
    newSocket.on("connect", () => {
      console.log("Connected to server");
    });

    newSocket.on("chatbot_answer", (data) => {
      if (data.isFinal) {
        setStreamMessage((prevStreamMessage) => {
          setMessages((prevMessages) => [
            ...prevMessages,
            { answer: prevStreamMessage, type: "bot", sources: [], images: [] },
          ]);
          return prevStreamMessage; // Keep the current streamMessage
        });

        setStreamMessage("");
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setStreamMessage(
          (prevStreamMessage) => prevStreamMessage + data.message
        );
      }
    });

    newSocket.on("source_url", (data) => {
      console.log("sources", data.url);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage && lastMessage.type === "bot") {
          lastMessage.sources = [...(lastMessage.sources || []), data.url];
        }
        return updatedMessages;
      });
    });

    newSocket.on("image_url", (data) => {
      console.log("data", data);

      // Check if data is not null and data.image is an array with at least one element
      if (data != null && Array.isArray(data.image) && data.image.length > 0) {
        console.log("here", data);

        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];

          if (lastMessage && lastMessage.type === "bot") {
            // Ensure lastMessage.images is initialized as an array if it doesn't exist yet
            lastMessage.images = [...(lastMessage.images || []), ...data.image];
          }

          return updatedMessages;
        });
      }
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      const botMessage = {
        type: "bot",
        answer: "Hello! How may I assist you today?",
        imagesLinks: [],
        sourceURLs: [],
      };
      setMessages([botMessage]);
      setUnique(1);
    }
  }, [socket]);

  const send = async (text) => {
    if (!socket) return;

    const userMessage = { type: "user", answer: text };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setIsLoading(true);

    socket.emit("chat", text);

    setText("");
  };

  return (
    <div className="chatbot">
      <Header />
      <Messages
        messages={messages}
        streamMessage={streamMessage}
        loading={isLoading}
      />
      <Input
        onSend={send}
        inpRef={inpRef}
        text={text}
        setText={setText}
        sendRef={sendRef}
      />
    </div>
  );
}

export default ChatBot;
