import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import ChatPage from './pages/ChatPage';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChatPage />
  // <React.StrictMode>
  //   <Router>
  //       <Routes>
  //           <Route path="/" element={<ChatPage />} />
  //       </Routes>
  //   </Router>
  // </React.StrictMode>
);

reportWebVitals();